import React from 'react';
import Checkmark from 'cccisd-icons/checkmark';

const CheckTrue = ({ props, isCsv }) => {
    if (props.value === 'Yes') {
        if (isCsv) {
            return 'Yes';
        }
        return <Checkmark />;
    }
    return null;
};

export default CheckTrue;
