import isEmpty from 'lodash/isEmpty';
const NameOnly = props => {
    const orgStaffName = props?.value;

    if (orgStaffName && !isEmpty(orgStaffName)) {
        const stringArr = orgStaffName.split('-');

        return stringArr[0].trim();
    }

    return null;
};

export default NameOnly;
