import React from 'react';
import style from './style.css';

export default ({ type, props: { value } }) => {
    if (type === 'open') {
        if (value) {
            return <div className={`${style.green} ${style.tag}`}>Open</div>;
        }
        return <div className={`${style.red} ${style.tag}`}>Closed</div>;
    }
    if (value === 'Complete') {
        return <div className={`${style.green} ${style.tag}`}>Complete</div>;
    }
    if (value === 'In Progress') {
        return <div className={`${style.yellow} ${style.tag}`}>In Progress</div>;
    }
    if (value === 'Not Started') {
        return <div className={`${style.red} ${style.tag}`}>Not Started</div>;
    }
    if (value === 'No Longer Applicable') {
        return <div className={`${style.yellow} ${style.tag}`}>Not Applicable</div>;
    }

    return <div className={`${style.red} ${style.tag}`}>Unanswered</div>;
};
