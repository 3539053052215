import React from 'react';
import _last from 'lodash/last';
const trainings = {
    TitleOption1: 'Circle of Parents Facilitator Training',

    TitleOption2: 'Community Resilience Model',

    TitleOption3: 'Connections Matter',

    TitleOption4: 'NFSN Standards of Quality',

    TitleOption5: 'Powerful Possibilities',

    TitleOption6: 'Promising Solutions',

    TitleOption7: 'Protective Factors Framework',

    TitleOption8: 'Protective Factors Train-the-Trainer',

    TitleOption9: 'Starting Strong',
};

export default ({ row, isCsv }) => {
    let trainingList = [];

    for (const [key, value] of Object.entries(trainings)) {
        if (row[`devTags.${key}`] === 'checked') {
            trainingList.push(value);
        }
    }

    if (row['devTags.SessionTitle']) {
        trainingList = [`${row['devTags.SessionTitle']}`];
    }

    if (trainingList.length > 0) {
        if (isCsv) {
            const last = _last(trainingList);
            return (
                <>
                    {trainingList.map((item, i) => {
                        if (item === last) {
                            return `${item}`;
                        }
                        return `${item}, `;
                    })}
                </>
            );
        }
        return (
            <ul>
                {trainingList.map((item, i) => (
                    <li key={i}>{item}</li>
                ))}
            </ul>
        );
    }

    return null;
};
