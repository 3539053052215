import React, { useState, useEffect } from 'react';
import SelectList from 'react-widgets/lib/SelectList';
import PropTypes from 'prop-types';
import DetectClickLocation from 'cccisd-detect-click-location';
import { CccisdFieldWrapper } from 'cccisd-formik';
import Button from 'cccisd-click-button';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import { useDispatch, connect } from 'react-redux';
import { setValues, setOrgListChanged } from 'js/reducers/orgList.js';

const MultiSelect = props => {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setValues(props.form, []));
    }, [props.form.values.project]);

    useEffect(() => {
        dispatch(setValues(props.form, props.orgList));
    }, [props.orgList]);

    useEffect(() => {
        if (props.isEdit && props.value && !props.changed) {
            dispatch(setValues(props.form, props.value));
        }
    }, []);

    const handleChange = value => {
        dispatch(setOrgListChanged(true));
        dispatch(setValues(props.form, value));
    };

    const selectAll = () => {
        dispatch(setOrgListChanged(true));
        dispatch(setValues(props.form, props.options));
    };

    const selectNone = () => {
        dispatch(setOrgListChanged(true));
        dispatch(setValues(props.form, []));
    };

    return (
        <CccisdFieldWrapper {...props}>
            <div className={style.wrapper}>
                <DetectClickLocation clickedOutside={() => setVisible(false)}>
                    <Tooltip
                        title={
                            <div className={style.toolTip}>
                                {props.orgList.length ? props.orgList.length : 0} {`Org(s) Selected`}
                            </div>
                        }
                    >
                        <Button
                            title={`Techncial Assistance Orgs (${props.orgList.length ? props.orgList.length : 0})`}
                            className="btn btn-default"
                            onClick={() => setVisible(!visible)}
                            style={{ display: 'block', marginBottom: '15px', marginRight: '-2em' }}
                        />
                    </Tooltip>
                    {visible && (
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 2,
                                marginTop: '-8px',
                                marginLeft: '8px',
                            }}
                        >
                            <Button
                                className={`btn btn-xs btn-default ${style.marginRight}`}
                                isDisabled={props.orgList.length === props.options.length}
                                onClick={selectAll}
                                title="Select All"
                            />
                            <Button
                                className="btn btn-xs btn-default"
                                isDisabled={props.orgList.length === 0}
                                onClick={selectNone}
                                title="Deselect All"
                            />
                        </div>
                    )}
                    <SelectList
                        multiple
                        data={props.options}
                        textField="label"
                        valueField="value"
                        onChange={handleChange}
                        value={props.orgList}
                        style={{
                            display: visible ? 'block' : 'none',
                            height: 'auto',
                            marginTop: '-15px',
                            position: 'absolute',
                            zIndex: 1,
                            paddingTop: '30px',
                        }}
                    />
                </DetectClickLocation>
            </div>
        </CccisdFieldWrapper>
    );
};

MultiSelect.propTypes = {
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    options: PropTypes.array,
    orgList: PropTypes.array,
    changed: PropTypes.bool,
};
const mapStateToProps = state => ({
    orgList: state.app.orgList.orgList,
    changed: state.app.orgList.changed,
});

export default connect(mapStateToProps, {
    setValues,
})(MultiSelect);
