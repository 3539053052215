import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'cccisd-formik';
import MultiSelect from '../MultiSelect';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import getDeploymentProps from '../../helpers/getDeploymentProps.js';
import ArrowLeft from 'cccisd-icons/arrow-left7';
import style from './style.css';
import dashboardConfig from '../../../config.js';

const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;

const Survey = props => {
    const { deploymentInfo, modalRef } = props;

    const mustSelectOrgs = deploymentInfo.handle === 'profDevDoc' || deploymentInfo.handle === 'networkMeetings';

    const projectLabel = props.selectOptions?.questProjectList?.find(
        item => item.value === props.questProjectId
    )?.label;

    const currentConfig = dashboardConfig[actingRole]?.questProjectList
        ?.find(item => item.label === projectLabel)
        ?.deployments?.find(item => item.handle === deploymentInfo.handle);

    const [loading, setLoading] = useState(true);
    const [configData, setConfigData] = useState(null);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        const surveyConfig = currentConfig.survey;

        if (mustSelectOrgs) {
            if (props.isView) {
                if (props.orgsSelected) {
                    getData(surveyConfig, JSON.parse(props.orgsSelected));
                }
            }
            if (props.isEdit && !props.isView) {
                getData(surveyConfig, props.orgsSelected);
                setShowForm(true);
            }

            if (!props.isEdit && !props.isView) {
                setShowForm(true);
            }
        } else {
            getData(surveyConfig);
        }
    }, []);

    const getData = async (config, values) => {
        setLoading(true);

        const settings = await getDeploymentProps(props, config, values);

        if (props.isView) {
            settings.flowProps.isPrintMode = true;
            // settings.file = {}
            // settings.file.name = `${deploymentInfo.assignmentLabel}`;
        }

        setConfigData(settings);
        setLoading(false);
    };

    const onComplete = async () => {
        notification({
            message: props.isReferral ? 'Referral Completed!' : 'Survey Submitted!',
            type: 'success',
        });
        modalRef?.current?.close();
        props.tableRef?.current?.loadData();
        // Dont know if this will be used?? CHANGE NEEDED!!!! - get Label from survey question query
        // if (deploymentInfo.handle === 'facilitatorForm') {
        //     try {
        //         const settings = {
        //             assignmentId: configData.deployment.assignment.assignmentId,
        //             description: null,
        //             hash: Math.random()
        //                 .toString(36)
        //                 .substring(2, 9),
        //             isOpen: false,
        //             label: 'TestName3',
        //             respondentsFromDcwId: null,
        //             roleHandle: 'respondent',
        //             opensAt: '',
        //             closesAt: '',
        //             settings: {
        //                 options: configData.surveyList,
        //                 orgId: props.groupingUnitId,
        //                 orgName: configData.orgName,
        //                 recaptcha: false,
        //                 autoLogout: true,
        //                 isRepeated: false,
        //                 allowRetake: false,
        //                 requirePass: false,
        //                 useEndpoint: false,
        //                 closedRoster: false,
        //                 requireLogin: true,
        //                 closeOnTarget: false,
        //                 closedMessage: 'This survey is no longer accepting responses.',
        //                 completionUrl: null,
        //                 passcodeLabel: 'Your Passcode',
        //                 autoLogoutTime: 20,
        //                 repeatInterval: null,
        //                 completedMessage: 'You have already completed this survey. Thank you!',
        //                 respondentConfig: 'managed',
        //                 completionMessage:
        //                     'You have completed the survey! Thank you for your response!',
        //                 otherAllowedRoles: ['uberadmin', 'questprojectadmin'],
        //                 chainEmailFromPawn: null,
        //                 chainPhoneFromPawn: null,
        //                 individualPasscode: false,
        //                 logoutOnCompletion: false,
        //                 passcodeBackground: 'solidColor',
        //                 respondentSelection: 'metricspawn',
        //                 postCompletionAction: 'message',
        //                 anonAutoLogoutMessage:
        //                     '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity.</p>\n                    <p>Sorry! <a href="https://pcanc.test/d/9frmzn1" target="_blank" rel="noreferrer noopener">Start a new session</a></p>',
        //                 knownAutoLogoutMessage:
        //                     '<p>Inactivity Timeout</p><p>We closed your survey session after a period of inactivity. Sorry!</p>\n                        <p>You can resume your session from the link you were given, or contact your survey administrator for further assistance.</p>',
        //                 passcodeBackgroundColor: '#F1F0F5',
        //                 branchedCompletionConfig: [],
        //                 isAssignmentPlanEligible: false,
        //                 passcodeCompletionButtonText: 'Ok',
        //                 showReviewButtonOnCompletion: false,
        //                 metricToRespondentPropertyMappings: [],
        //             },
        //             timepoint: '',
        //             type: 'group',
        //         };

        //         const response = await axios.post(
        //             Boilerplate.route('api.assignmentDeployment.store'),
        //             settings
        //         );

        //         if (response?.data?.status === 'success') {
        //             window.location = Boilerplate.url('dashboard');
        //         } else {
        //             notification({ message: response?.data?.errors?.group[0], type: 'danger' });
        //         }
        //     } catch (e) {
        //         console.error(e);
        //     }
        // }
    };

    const onSubmit = values => {
        getData(currentConfig.survey, values);
        setShowForm(false);
    };
    const form = initialValues => {
        return (
            <Form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Field
                        name="multiSelect"
                        component={MultiSelect}
                        label="Select TA Org(s):"
                        options={props.selectOptions.guList}
                        value={initialValues?.multiSelect && initialValues?.multiSelect}
                        isEdit
                    />
                </div>
                <div className={style.buttonRow}>
                    <button type="button" className="btn btn-default">
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </Form>
        );
    };

    const renderForm = () => {
        let initialValues = {};
        if (props.orgsSelected && props.orgsSelected.length > 0) {
            const vals = JSON.parse(props.orgsSelected);
            initialValues.multiSelect = vals?.multiSelect;
        }
        if (initialValues.multiSelect?.length > 0) {
            return (
                <Formik
                    onSubmit={onSubmit}
                    render={() => {
                        return <>{form(initialValues)}</>;
                    }}
                />
            );
        }
        return (
            <Formik
                onSubmit={onSubmit}
                render={() => {
                    return <>{form()}</>;
                }}
            />
        );
    };

    return (
        <>
            {showForm ? (
                <>{renderForm()}</>
            ) : (
                <>
                    {mustSelectOrgs && !props.isView && (
                        <button
                            className="btn btn-warning"
                            onClick={() => {
                                setShowForm(true);
                            }}
                            type="button"
                        >
                            <ArrowLeft spaceRight /> Return to TA Org Selection
                        </button>
                    )}
                    <Loader loading={loading} removeChildren>
                        {configData && <DeploymentPlayer {...configData} onComplete={onComplete} />}
                    </Loader>
                </>
            )}
        </>
    );
};

export default Survey;
