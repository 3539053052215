import Modal from 'cccisd-modal';
import React, { useRef } from 'react';
import style from './style.css';
import Tooltip from 'cccisd-tooltip';
import PencilIcon from 'cccisd-icons/pencil2';
import GlassIcon from 'cccisd-icons/search';
import Survey from '../../components/Survey';
import { setOrgListChanged, setOrgList } from '../../../../../reducers/orgList.js';
import { useDispatch } from 'react-redux';
const EditSurvey = ({
    tableProps,
    tableRef,
    deploymentId,
    isView,
    loadData,
    checkProgress,
    spaceRight,
    orgsSelected,
}) => {
    const dispatch = useDispatch();
    const modal = useRef();
    let newProps = { ...tableProps, deploymentId };

    newProps.tableRef = tableRef;
    newProps.modalRef = modal;
    return (
        <>
            <Modal
                key={deploymentId}
                ref={modal}
                trigger={
                    <Tooltip title={isView ? 'View Survey' : 'Edit Survey'}>
                        <button
                            key={deploymentId}
                            type="button"
                            className={isView ? 'btn btn-primary' : 'btn btn-success'}
                            style={spaceRight ? { marginRight: '1em' } : {}}
                        >
                            {isView ? <GlassIcon /> : <PencilIcon />}
                        </button>
                    </Tooltip>
                }
                title={isView ? 'View Survey' : 'Edit Survey'}
                size="large"
                afterClose={() => {
                    loadData();
                    checkProgress();
                    dispatch(setOrgListChanged(false));
                    dispatch(setOrgList([]));
                }}
            >
                <div className={style.fixContainer}>
                    <Survey {...newProps} isView={isView} isEdit orgsSelected={orgsSelected} />
                </div>
            </Modal>
        </>
    );
};

export default EditSurvey;
