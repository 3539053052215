import React from 'react';
import style from './style.css';

export default props => {
    const isComplete = props.value === 'Yes';

    if (isComplete) {
        return <div className={`${style.green} ${style.tag}`}>Complete</div>;
    }
    return <div className={`${style.yellow} ${style.tag}`}>In Progress</div>;
};
