import React from 'react';
import Modal from 'cccisd-modal';
import Clipboard from 'cccisd-icons/clipboard4';
import Tooltip from 'cccisd-tooltip';

const SiteLead = props => {
    const topics = {
        ProgressPOC1: 'Community Planning & Advocacy',
        ProgressPOC2: 'Child Safeguarding TA',
        ProgressPOC3: 'Implementation Support for Parent Education EBPs',
        ProgressPOC4: 'NC FRC Network',
        ProgressPOC5: 'Public Awareness Support',
        ProgressPOC6: 'Organization Capacity Building',
        ProgressPOC7: 'Other',
    };

    const list = [];

    for (const [key, value] of Object.entries(topics)) {
        if (props.row[`devTags.${key}`]) {
            list.push({ name: value, value: props.row[`devTags.${key}`] });
        }
    }

    if (list.length > 0) {
        return (
            <Modal
                trigger={
                    <Tooltip title="View PCANC Site Leads">
                        <button type="button" className="btn btn-primary">
                            <Clipboard />
                        </button>
                    </Tooltip>
                }
                title="PCANC Site Leads"
                size="medium"
            >
                <div>
                    <table className="table table-striped tabled-bordered">
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Site Lead</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item.name}</td>
                                        <td>{item.value}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Modal>
        );
    }
    return null;
};

export default SiteLead;
