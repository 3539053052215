import React from 'react';
import Modal from 'cccisd-modal';
import _last from 'lodash/last';
import Tooltip from 'cccisd-tooltip';

const SelectedOrgs = ({ props, attendees, isCsv }) => {
    const orgSelection =
        props.row['orgSelection.app_variable_orgSelection'] &&
        JSON.parse(props.row['orgSelection.app_variable_orgSelection'])?.multiSelect.map(item => item.label);

    const attended = props.row['devTags.AdminFacAttended'] && JSON.parse(props.row['devTags.AdminFacAttended']);

    const list = attendees ? attended : orgSelection;

    if (isCsv) {
        const last = _last(list);
        return (
            <>
                {list &&
                    list.map((item, i) => {
                        if (item === last) {
                            return `${item}`;
                        }
                        return `${item}, `;
                    })}
            </>
        );
    }

    return (
        <>
            {list && list.length > 0 ? (
                <Modal
                    key={props.row['deployment.deploymentId']}
                    trigger={
                        <Tooltip title={attendees ? 'View Attendees' : 'View Organizations Represented'}>
                            <a href="#">{list.length}</a>
                        </Tooltip>
                    }
                    title={attendees ? 'Attendees' : 'Organizations Represented'}
                    size="medium"
                    beforeShow={() => {}}
                    afterClose={() => {}}
                    disabled={list.length === 0}
                >
                    <ul>
                        {list.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                    </ul>
                </Modal>
            ) : null}
        </>
    );
};

export default SelectedOrgs;
