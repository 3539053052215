import React from 'react';
import Modal from 'cccisd-modal';
import _last from 'lodash/last';
import Tooltip from 'cccisd-tooltip';

const GoalAttendance = ({ props, isStaff, isCsv }) => {
    const values = props.value ? JSON.parse(props.value) : [];

    if (isCsv && values.length > 0) {
        const last = _last(values);
        return (
            <>
                {values &&
                    values.map((item, i) => {
                        if (item === last) {
                            return `${item}`;
                        }
                        return `${item}, `;
                    })}
            </>
        );
    }

    return (
        <>
            {values.length > 0 ? (
                <Modal
                    key={props.row['deployment.deploymentId']}
                    trigger={
                        <Tooltip
                            title={isStaff ? 'View PCANC Staff Members' : 'View Organization Admins / Facilitators'}
                        >
                            <a href="#">{values.length}</a>
                        </Tooltip>
                    }
                    title={isStaff ? 'PCANC Staff Members' : 'Organization Admins / Facilitators'}
                    size={isStaff ? 'small' : 'medium'}
                    beforeShow={() => {}}
                    afterClose={() => {}}
                    disabled={values.length === 0}
                >
                    <ul>
                        {values.map((item, i) => (
                            <li key={i}>{item}</li>
                        ))}
                    </ul>
                </Modal>
            ) : null}
        </>
    );
};

export default GoalAttendance;
